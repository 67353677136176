import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import NotFoundContent from 'components/sections/not-found-content';

const NotFoundPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="404: Not found" />
    <NotFoundContent />
  </Layout>
);

export default NotFoundPage;
